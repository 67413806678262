import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import { ErrorComponent } from "../error";
import { useGetProfileSelectorDataQuery } from "../guardianship/get-profile-selector-data.graphql";
import { useSwitchSessionMutation } from "../guardianship/switch-session.graphql";
import { Loading } from "../loading";
import { getConfig } from "../runtime-config";
import { colors } from "../theme";
import { splitByComma } from "../utils";

const { ALLOW_GUARDIANSHIP } = getConfig();

function WelcomeNewAppointment({ careUnitNames }: { careUnitNames: string[] }) {
  const location = useLocation();
  const [searchParameters] = useSearchParams();
  const caregiverIds = splitByComma(searchParameters.get("caregiverId"));
  const bookingTypeIds = splitByComma(searchParameters.get("bookingTypeId"));

  if (caregiverIds.length > 0) {
    searchParameters.set("caregiverProvided", "true");
  }
  if (bookingTypeIds.length > 0) {
    searchParameters.set("bookingTypeProvided", "true");
  }

  const [
    switchSession,
    { error: switchSessionError, loading: switchSessionLoading },
  ] = useSwitchSessionMutation();

  const {
    data: { guardian, patient } = {},
    loading: profileSelectorLoading,
    error: profileSelectorError,
  } = useGetProfileSelectorDataQuery();

  useEffect(() => {
    if (guardian && patient?.id !== guardian?.id) {
      switchSession({
        variables: {
          patientId: guardian.id ?? patient?.id ?? "",
        },
      });
    }
  }, [patient, guardian, switchSession]);

  if (profileSelectorLoading || switchSessionLoading) {
    return (
      <Box>
        <Loading logo={false} text={t`Loading...`} />
      </Box>
    );
  }
  if (profileSelectorError || switchSessionError) {
    return <ErrorComponent component="new-booking-error" />;
  }
  return (
    <>
      <Typography
        sx={{
          color: colors.zymegoDarkGreen,
          fontSize: "22px",
          fontWeight: 600,
          marginBottom: 2,
          textAlign: "center",
        }}
      >
        <Trans>Select for whom</Trans>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          paddingX: "24px",
        }}
      >
        <Box
          sx={{
            border: `2px solid ${colors.grey}`,
            borderRadius: "14px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            paddingX: "20px",
            paddingY: "20px",
          }}
        >
          {careUnitNames.map((careUnitName) => (
            <div key={careUnitName}>
              <Typography
                sx={{
                  color: colors.zymegoDarkGreen,
                  fontSize: "22px",
                  fontWeight: 500,
                  lineHeight: 1.2,
                }}
              >
                {careUnitName}
                <br />
              </Typography>
            </div>
          ))}
          <Typography
            sx={{
              color: colors.zymegoDarkGreen,
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {ALLOW_GUARDIANSHIP ? (
              <Trans>
                Select if you want to book an appointment for yourself or for a
                child
              </Trans>
            ) : (
              <Trans>Book a new appointment for yourself</Trans>
            )}
          </Typography>
        </Box>
        <Button
          component={Link}
          endIcon={<ArrowForward />}
          fullWidth
          sx={{ margin: 0 }}
          to={{
            pathname: "type-of-appointment",
            search: `${searchParameters}`,
          }}
          variant="contained"
        >
          {ALLOW_GUARDIANSHIP ? (
            <Trans>Book for myself</Trans>
          ) : (
            <Trans>Book a new appointment</Trans>
          )}
        </Button>
        {ALLOW_GUARDIANSHIP ? (
          <Button
            color="secondary"
            component={Link}
            endIcon={<ArrowForward />}
            fullWidth
            state={{ backgroundLocation: location }}
            sx={{ margin: 0 }}
            to={{ pathname: "add-guardianship", search: `${searchParameters}` }}
            variant="contained"
          >
            <Trans>Book for a child</Trans>
          </Button>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
export { WelcomeNewAppointment };
