import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { ErrorComponent } from "../error";
import { useGetBookingTypesQuery } from "../get-booking-types.graphql";
import { ReactComponent as ArrowBottom } from "../icons/arrow-botton-double-chevron.svg";
import { Loading } from "../loading";
import { colors } from "../theme";
import { CareUnitTheme, RescheduleTimeTable } from "../time-table";
import {
  deepLinkWithProvidedBookingTypeId,
  deepLinkWithProvidedCaregiverId,
  splitByComma,
} from "../utils";
import { useCareUnitsAndTimeslots } from "./use-care-units-and-timeslots";

type FormValues = {
  id: string;
};

function SelectTimeslot() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();
  const [isAtBottom, setIsAtBottom] = useState(false);

  const bookingTypeId = searchParameters.get("bookingTypeId");
  const bookingTypeIds = splitByComma(bookingTypeId);
  const caregiverId = searchParameters.get("caregiverId");
  const caregiverIds =
    caregiverId === "all" ? undefined : splitByComma(caregiverId);
  const careUnitUrlFriendlyNames = splitByComma(
    searchParameters.get("clinicName"),
  );
  const earliestStartAt = searchParameters.get("earliestStartAt");
  const fetchTimeslotRequestId = searchParameters.get("requestId");
  const latestEndAt = searchParameters.get("latestEndAt");
  const shortlinkIdent = searchParameters.get("link");

  invariant(bookingTypeId);
  invariant(caregiverId);
  invariant(fetchTimeslotRequestId);

  const { data: { bookingTypes } = {} } = useGetBookingTypesQuery({
    fetchPolicy: "network-only",
    skip: bookingTypeIds.length === 0,
    variables: { bookingTypeIds },
  });

  const query = useCareUnitsAndTimeslots({
    variables: {
      bookingTypeIds,
      caregiverIds,
      careUnitUrlFriendlyNames,
      earliestStartAt: earliestStartAt ? parseISO(earliestStartAt) : undefined,
      fetchTimeslotRequestId,
      latestEndAt: latestEndAt ? parseISO(latestEndAt) : undefined,
    },
  });

  const { careUnits, timeslots = [] } = query;

  const careUnitThemes = useMemo(() => {
    const map = new Map<string, CareUnitTheme>();
    const themes: CareUnitTheme[] = [
      {
        backgroundColor: colors.zymegoSpearMint,
        borderColor: colors.zymegoWarmGreen,
        textColor: colors.zymegoGreen,
      },
      {
        backgroundColor: colors.zymegoPowder,
        borderColor: colors.zymegoRose,
        textColor: colors.black,
      },
    ];

    for (const [index, careUnit] of careUnits?.entries() ?? []) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const theme = themes[index % themes.length]!;
      map.set(careUnit.id, theme);
    }

    return map;
  }, [careUnits]);

  useEffect(() => {
    const selector = "#root";
    const rootElement = document.querySelector(selector);

    if (!rootElement) {
      throw new ReferenceError(
        `Selector '${selector}' did not match any element.`,
      );
    }

    const updateScrollPosition = () => {
      const distanceFromBottom = Math.abs(
        window.innerHeight + window.scrollY - document.body.scrollHeight,
      );
      setIsAtBottom(distanceFromBottom < 32);
    };

    // Update the scroll position as soon as the timeslots load:
    updateScrollPosition();

    window.addEventListener("scroll", updateScrollPosition);

    return () => {
      window.removeEventListener("scroll", updateScrollPosition);
    };
  }, [timeslots.length]);

  const {
    control,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>();

  const data = watch();

  const onSubmit = ({ id }: FormValues) => {
    if (!deepLinkWithProvidedBookingTypeId(location)) {
      searchParameters.delete("bookingTypeId");
    }

    if (!deepLinkWithProvidedCaregiverId(location)) {
      searchParameters.delete("caregiverId");
    }

    searchParameters.delete("requestId");
    searchParameters.set("timeslotId", id);

    if (shortlinkIdent) {
      searchParameters.set("link", shortlinkIdent);
    }

    navigate({
      pathname: "../booking-information",
      search: `${searchParameters}`,
    });
  };

  if (query.status === "error") {
    const { errorMessage } = query;
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={errorMessage?.header}
        errorMessage={errorMessage?.message}
      />
    );
  }

  if (query.status === "loading") {
    return (
      <Loading
        logo={false}
        text={t`We are fetching available time slots from the patient record system. This may take a while.`}
      />
    );
  }

  if (timeslots.length === 0) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`We could not find any available time slots`}
        errorMessage={t`Unfortunately we could not find any available time slots at this time. Please try again later!`}
      />
    );
  }

  const bookingTypeNames = _.uniq(bookingTypes?.map((type) => type.name));

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ flex: "auto" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: "24px",
          height: "100%",
          justifyContent: "space-between",
          paddingX: "24px",
        }}
      >
        <Box
          sx={{
            color: colors.zymegoDarkGreen,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
            <Trans>Select date & time</Trans>
          </Typography>
          <Typography sx={{ fontSize: "19px", fontWeight: 400 }}>
            {bookingTypeNames}
          </Typography>
        </Box>
        {careUnits && careUnits.length > 1 && (
          <Box
            sx={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "repeat(2, 1fr)",
              justifyContent: "space-between",
              paddingY: "8px",
            }}
          >
            {careUnits.map((careUnit) => (
              <Box key={careUnit.id} sx={{ display: "flex", gap: "12px" }}>
                <Box
                  sx={{
                    backgroundColor: careUnitThemes.get(careUnit.id)
                      ?.backgroundColor,
                    borderRadius: "20%",
                    boxShadow: "0 0 1px rgba(0, 0, 0, 0.1)",
                    flexShrink: 0,
                    height: "20px",
                    width: "25px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: 1.2,
                    marginTop: "2px",
                  }}
                >
                  {careUnit.name}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        <RescheduleTimeTable
          careUnitThemes={careUnitThemes}
          formProperties={{ control, getValues }}
          timeslots={timeslots}
        />
      </Box>
      <Box
        sx={{
          pointerEvents: "none",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            margin: "auto",
            padding: "112px 0px 10px 0px",
            maxWidth: "444px",
            textAlign: "center",
            backgroundImage:
              "linear-gradient(#ffffff00 0%, #ffffffa0 50%, #ffffffd0 90%)",
            pointerEvents: "none",
            transition: "opacity 0.3s",
            opacity: isAtBottom ? 0 : 1,
          }}
        >
          <ArrowBottom />
        </Box>
        <Box
          sx={{
            background: "white",
            margin: "auto",
            maxWidth: "444px",
            pointerEvents: "all",
            textAlign: "center",
          }}
        >
          <Box sx={{ paddingBottom: "24px", paddingX: "24px" }}>
            {errors && (
              <Typography color={colors.red} variant="subtitle2">
                {errors.id?.message}
              </Typography>
            )}
            <Button
              disabled={!data.id}
              endIcon={<ArrowForward />}
              fullWidth
              sx={{ marginBottom: 0 }}
              type="submit"
              variant="contained"
            >
              <Trans>Next</Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

export { SelectTimeslot };
