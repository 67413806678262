import { t, Trans } from "@lingui/macro";
import {
  AccessTime,
  CalendarMonth,
  CheckCircleOutline,
} from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";

import { trackEvent } from "../analytics";
import { AuthShield } from "../auth/auth-shield";
import { Button } from "../components/button/button";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { ErrorComponent } from "../error";
import { Loading } from "../loading";
import { useAuthLevel } from "../nhs/user-auth-level";
import { formatPatientIdentifier } from "../patient/identifier";
import { colors } from "../theme";
import { useUrls } from "../urls";
import { useGetNewAppointmentStatusQuery } from "./fetch-new-appointment-request-status.graphql";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { useGetTimeslotQuery } from "./get-timeslot.graphql";

function StatusNewAppointment() {
  const { authLevel, loading: isAuthLevelLoading } = useAuthLevel();
  const { formatDate, formatTime } = useDateFormatter();
  const location = useLocation();
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const [searchParameters] = useSearchParams();
  const urls = useUrls();

  invariant(requestId, "requestId is missing from url");
  const timeslotId = searchParameters.get("timeslotId");
  invariant(timeslotId, "timeslot Id is not provided");

  const showDialogTimeoutHandle = useRef<ReturnType<typeof setTimeout>>();

  const {
    loading: statusLoading,
    error: statusError,
    data: statusData,
    stopPolling,
  } = useGetNewAppointmentStatusQuery({
    variables: { requestId },
    pollInterval: 200,
  });

  const {
    data: { patient } = {},
    loading: currentPatientLoading,
    error: currentPatientError,
  } = useGetCurrentPatientQuery();

  const {
    loading,
    error,
    data: { timeslot } = {},
  } = useGetTimeslotQuery({ variables: { timeslotId } });

  useEffect(() => {
    if (
      statusData &&
      statusData.createAppointmentRequest?.status === "SUCCESS"
    ) {
      trackEvent("new-appointment", {
        props: {
          careUnitId: statusData.createAppointmentRequest.careUnitId,
          careUnitName:
            statusData.createAppointmentRequest.careUnit?.name ?? "",
        },
      });
    }
  }, [statusData]);

  useEffect(() => {
    if (
      !statusLoading &&
      statusData?.createAppointmentRequest &&
      ["SUCCESS", "FAILED"].includes(statusData.createAppointmentRequest.status)
    ) {
      stopPolling();
    }
  }, [statusLoading, statusData, stopPolling]);

  useEffect(() => {
    if (
      statusData &&
      statusData.createAppointmentRequest?.status === "SUCCESS"
    ) {
      showDialogTimeoutHandle.current = setTimeout(() => {
        navigate(
          { pathname: "accept", search: `${searchParameters}` },
          {
            state: { backgroundLocation: location },
          },
        );
      }, 1000);
    }
    return () => {
      if (showDialogTimeoutHandle.current) {
        clearTimeout(showDialogTimeoutHandle.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData]);

  const handleBookAnotherAppointment = () => {
    searchParameters.delete("timeslotId");
    navigate({ pathname: "/bookings/new", search: `${searchParameters}` });
  };

  if (
    currentPatientError ||
    error ||
    statusError ||
    statusData?.createAppointmentRequest?.status === "FAILED"
  ) {
    return <ErrorComponent component="new-booking-error" />;
  }

  if (
    currentPatientLoading ||
    loading ||
    statusLoading ||
    (statusData?.createAppointmentRequest?.status === "IN_PROGRESS" && !loading)
  ) {
    return (
      <Box>
        <Loading logo={false} text={t`Creating appointment...`} />
      </Box>
    );
  }

  const bookingTypeName =
    timeslot?.bookingType?.visibleName ?? timeslot?.bookingType?.name;

  const clinicName = searchParameters.get("clinicName") ?? "";
  const careUnitUrl =
    statusData?.createAppointmentRequest?.careUnit?.externalHomePageUrl;

  const patientDisplayIdentifier = formatPatientIdentifier(
    patient?.identifier,
    { maskIdentifier: authLevel !== "high" },
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        paddingX: "24px",
      }}
    >
      <Typography
        sx={{
          color: colors.zymegoDarkGreen,
          fontSize: "22px",
          fontWeight: 600,
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        <Trans>Booking confirmation</Trans>
      </Typography>
      <Box>
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: colors.zymegoAccessibleGreen,
            borderTopLeftRadius: "14px",
            borderTopRightRadius: "14px",
            ...(!timeslot && { borderRadius: "14px" }),
            color: colors.white,
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 24px",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: 600, lineHeight: 1.3 }}
          >
            <Trans>You have booked an appointment!</Trans>
          </Typography>
          <CheckCircleOutline />
        </Box>
        {timeslot && (
          <Box
            sx={{
              border: `2px solid ${colors.grey}`,
              borderBottomLeftRadius: "14px",
              borderBottomRightRadius: "14px",
              borderTop: "none",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "20px 24px",
            }}
          >
            <Box>
              {timeslot.careUnit && (
                <Typography sx={{ fontSize: "25px", fontWeight: 500 }}>
                  {timeslot.careUnit.name}
                </Typography>
              )}
              <Typography
                sx={{ fontSize: "18px", fontWeight: 600, lineHeight: 1.7 }}
              >
                <Trans>Appointment for {patientDisplayIdentifier}</Trans>
              </Typography>
              {timeslot.caregiver && (
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  {bookingTypeName}{" "}
                  <Trans>with {timeslot.caregiver.name}</Trans>
                </Typography>
              )}
              {timeslot.careUnit && (
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  {timeslot.careUnit.address}, {timeslot.careUnit.postAddress}{" "}
                  {timeslot.careUnit.postCode}
                </Typography>
              )}
              {careUnitUrl && (
                <Link
                  href={careUnitUrl}
                  sx={{
                    color: colors.zymegoDarkGreen,
                    fontSize: "14px",
                    fontWeight: 500,
                    textDecorationColor: colors.zymegoDarkGreen,
                  }}
                >
                  {careUnitUrl}
                </Link>
              )}
            </Box>
            <Box
              sx={{
                backgroundColor: colors.zymegoSpearMint,
                borderRadius: "14px",
                display: "flex",
                justifyContent: "space-between",
                padding: "16px",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  gap: "8px",
                  justifyContent: "space-between",
                }}
              >
                <CalendarMonth />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    ":first-letter": { textTransform: "uppercase" },
                  }}
                >
                  {formatDate(new Date(timeslot.startAtInCareUnitsTimezone))}
                </Typography>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  gap: "8px",
                  justifyContent: "space-between",
                }}
              >
                <AccessTime />
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  {formatTime(new Date(timeslot.startAtInCareUnitsTimezone))}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {isAuthLevelLoading ? (
        <Loading text={t`Loading...`} />
      ) : authLevel === "high" ? (
        <>
          <Button
            disabled={loading || statusLoading}
            href="/"
            sx={{ margin: 0 }}
          >
            <Trans>My appointments</Trans>
          </Button>
          <Button
            design="white-bordered"
            onClick={handleBookAnotherAppointment}
            sx={{ margin: 0 }}
          >
            <Trans>Back to start</Trans>
          </Button>
        </>
      ) : (
        <AuthShield
          onBackClick={handleBookAnotherAppointment}
          requiredAuthLevel="high"
          state={{
            locationState: {
              pathname: urls.bookings.new.index,
              search: `?clinicName=${clinicName}`,
              state: globalThis.history.state,
            },
          }}
        />
      )}
      <Button design="transparent" href="/logout" icon="logout">
        <Trans>Sign out</Trans>
      </Button>
    </Box>
  );
}

export { StatusNewAppointment };
